import { t } from 'i18next';
import Lottie from 'lottie-react';
import { lazy, LazyExoticComponent, Suspense } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';

import AppLoader from '~assets/loader.json';
import { useLocalStorage } from '~hooks/index';
import {
  PrivateRoute,
  privateRouteOptions,
  PublicRoute,
  ROUTES
} from './index';

const LandingPage = lazy(() => import('~pages/Public/LandingPage/LandingPage'));
const LoginPage = lazy(() => import('~pages/Public/LoginPage/LoginPage'));
const Error404 = lazy(() => import('~pages/Error404/Error404'));

const Home = lazy(() => import('~pages/Private/Home/Home'));
const CreateWorkList = lazy(
  () => import('~pages/Private/CreateWorkList/CreateWorkList')
);
const Dashboard = lazy(() => import('~pages/Private/Dashboard/Dashboard'));
const FileLoad = lazy(
  () => import('~pages/Private/MassiveLoad/FileLoad/FileLoad')
);
const PatientList = lazy(
  () => import('~pages/Private/PatientList/PatientList')
);
const FileHistory = lazy(
  () => import('~pages/Private/MassiveLoad/FileHistory/FileHistory')
);
const ObservationZone = lazy(
  () => import('~pages/Private/ObservationZone/Rooms/ObservationZone')
);
const LocationWithPatients = lazy(
  () =>
    import(
      '~pages/Private/ObservationZone/LocationWithPatients/LocationWithPatients'
    )
);
const WorkUnit = lazy(() => import('~pages/Private/WorkUnit/WorkUnit'));
const GenericCohort = lazy(
  () => import('~layouts/Cohort/GenericCohort/GenericCohort')
);
const Users = lazy(() => import('~pages/Private/Users/Users'));
const UserManagementForm = lazy(
  () => import('~pages/Private/Users/UserManagementForm/UserManagementForm')
);
const GroupsManagement = lazy(
  () => import('~pages/Private/Users/GroupsManagement/GroupsManagement')
);

//* Private routes from implementations
const FciSchedule = lazy(
  () => import('~implementations/fci/pages/Schedule/Schedule')
);
const FciCohort = lazy(
  () => import('~implementations/fci/pages/Cohorts/FciCohorts')
);
const FciControlTower = lazy(
  () => import('~implementations/fci/pages/ControlTower/ControlTower')
);
const RimacCohort = lazy(
  () => import('~implementations/rimac/pages/Cohorts/RimacCohorts')
);
const HorizonteCohort = lazy(
  () => import('~implementations/horizonte/pages/Cohorts/RimacCohorts')
);
const Reports = lazy(() => import('~pages/Private/Reports/Reports'));
const CohortAssign = lazy(() => import('~pages/Private/CohortAssign/CohortAssign'));
const ColManagePatients = lazy(
  () =>
    import('~implementations/colsubsidio/pages/ManagePatients/ManagePatients')
);
const ColSchedulePatients = lazy(
  () =>
    import(
      '~implementations/colsubsidio/pages/ManagePatients/SchedulePatients/SchedulePatients'
    )
);
const ColApplications = lazy(
  () => import('~implementations/colsubsidio/pages/Applications/Applications')
);
const ColProgrammingAssistant = lazy(
  () => import('~implementations/colsubsidio/pages/ProgrammingAssistant/ProgrammingAssistant')
);
const CoosaludCohort = lazy(
  () => import('~implementations/coosalud/pages/Cohorts/Rimac/RimacCohorts')
);
const CoosaludCohortGrouped = lazy(
  () => import('~implementations/coosalud/pages/Cohorts/Fci/FciCohorts')
);
const CoosaludControlTower = lazy(
  () => import('~implementations/coosalud/pages/ControlTower/ControlTower')
);


const RoutesDirectory = () => {
  const { userData } = useLocalStorage();

  // TODO: Page title rendering pending
  // const language = getLanguage();

  const appPageDirectory: Record<
    string,
    LazyExoticComponent<({ urlPath }: { urlPath?: string }) => JSX.Element>
  > = {
    //* Here will be the common components for all implementations
    cohort: GenericCohort,
    create_worklist: CreateWorkList,
    dashboard: Dashboard,
    file_history: FileHistory,
    file_load: FileLoad,
    observation_zone: ObservationZone,
    patient_list: PatientList,
    users: Users,
    work_unit: WorkUnit,
    reports: Reports,
    cohort_assign: CohortAssign,

    //* Here will be the components used by the implementations
    fci_cohort: FciCohort,
    fci_control_tower: FciControlTower,
    fci_schedule: FciSchedule,
    rimac_cohort: RimacCohort,
    horizonte_cohort: HorizonteCohort,
    col_manage_patients: ColManagePatients,
    col_applications: ColApplications,
    col_programming_assistant: ColProgrammingAssistant,
    coosalud_cohort: CoosaludCohort,
    coosalud_cohort_grouped: CoosaludCohortGrouped,
    coosalud_control_tower: CoosaludControlTower
  };

  const pageSelected = (name: string) => {
    const Page = appPageDirectory[name];
    return <Page />;
  };

  const router = createBrowserRouter([
    {
      path: '*',
      element: <Navigate to={ROUTES.ERROR} />
    },
    {
      path: ROUTES.ERROR,
      element: <Error404 />
    },
    {
      path: ROUTES.ROOT,
      element: <PublicRoute />,
      children: [
        {
          index: true,
          element: <LandingPage />
        },
        {
          path: ROUTES.LOGIN,
          element: <LoginPage />
        }
      ]
    },
    {
      path: ROUTES.PLATFORM,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.PLATFORM,
          element: <Home />
        },
        {
          path: ROUTES.CREATE_LIST,
          element: (
            <>
              <h2 className="page_title">{t('routes.create_work_list')}</h2>
              <CreateWorkList />
            </>
          )
        },
        {
          path: ROUTES.USER_UPDATE,
          element: (
            <>
              <h2 className="page_title">{t('routes.update_user')}</h2>
              <UserManagementForm />
            </>
          )
        },
        {
          path: ROUTES.USER_CREATE,
          element: (
            <>
              <h2 className="page_title">{t('routes.create_user')}</h2>
              <UserManagementForm />
            </>
          )
        },
        {
          path: ROUTES.GROUPS_MANAGEMENT,
          element: (
            <>
              <h2 className="page_title">{t('routes.groups')}</h2>
              <GroupsManagement />
            </>
          )
        },
        {
          path: ROUTES.LOCATION_WITH_PATIENTS,
          element: (
            <>
              <h2 className="page_title">
                {t('routes.location_with_patients')}
              </h2>
              <LocationWithPatients />
            </>
          )
        },
        {
          path: ROUTES.SCHEDULE_MANAGE,
          element: (
            <>
              <h2 className="page_title">{t('routes.schedule_patients')}</h2>
              <ColSchedulePatients />
            </>
          )
        },
        ...privateRouteOptions(userData.sidebarData).map((item) => {
          return {
            path: `/platform/${item.functional_name}`,
            element: (
              <>
                {!['work_unit', 'dashboard'].includes(item.functional_name!) ? (
                  <>
                    {/* TODO: Page title rendering pending */}
                    {/* <h2 className="page_title">{getLocalizedLabel(item, language)}</h2> */}
                    <div className='py-5'>
                      {pageSelected(item.functional_name!)}
                    </div>
                  </>
                ) : (
                  pageSelected(item.functional_name!)
                )}
              </>
            )
          };
        })
      ]
    }
  ]);

  return (
    <Suspense
      fallback={
        <div className="loadingContainer">
          <Lottie animationData={AppLoader} loop={true} />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default RoutesDirectory;
