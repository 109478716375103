import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import En from '~assets/flag_en.svg';
import Es from '~assets/flag_es.svg';
import Por from '~assets/flag_por.svg';
import { useLocalStorage } from '~hooks/index';
import './Internationalization.scss';
import { Button } from '~components/index';

export type LanguageCode = 'es' | 'en' | 'por';

const Internationalization = () => {
  const defaultLanguage = import.meta.env.VITE_DEFAULT_LANGUAGE as LanguageCode;
  const { setLanguage, getLanguage } = useLocalStorage();

  useEffect(() => {
    const currentLanguage = getLanguage() || defaultLanguage;
    i18next.changeLanguage(currentLanguage);
  }, []);

  const handleLanguageChange = (language: LanguageCode) => {
    setLanguage(language);
    window.location.reload();
  };

  const languageActions = [
    {
      acronym: 'ES',
      label: 'Español',
      onClick: () => handleLanguageChange('es'),
      icon: Es
    },
    {
      acronym: 'EN',
      label: 'English',
      onClick: () => handleLanguageChange('en'),
      icon: En
    },
    {
      acronym: 'POR',
      label: 'Português',
      onClick: () => handleLanguageChange('por'),
      icon: Por
    }
  ];

  return (
    <div className="internationalization d-flex align-items-center gap-2">
      <div className="d-flex gap-3">
        {languageActions.map((language, index) => (
          <div key={`language_${index}`} onClick={language.onClick} style={{cursor:'pointer'}} >
            <span style={{fontSize:"12px"}}>{language.acronym}{" "}</span>
            <img
              src={language.icon}
              alt={language.label}
              className="language-icon"
              style={{ width: '20px', height: '20px' }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Internationalization;
